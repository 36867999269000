export function formatAmount(value, decimal = 2) {
  if (!value) value = 0;

  const og = Math.pow(10, decimal);
  value = Math.floor(value * og) / og;

  return Number(value.toFixed(decimal));
}

export function currencyMask(value, min = 2, max = 2) {
  value = formatAmount(value, min);

  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
}

export function parseNumber(value) {
  value = value.split(" ")[0];

  return Number(value.replace(/\./g, "").replace(/,/g, "."));
}
