<template>
  <v-container class="home-wrapper">
    <v-row class="fill-height" align="center" justify="center" no-gutters>
      <v-col cols="12" sm="9" md="6" lg="5" xl="3">
        <div class="text-h4 font-weight-black font-italic text-center mb-8">
          SWAP - <span class="primary--text">Cryptospace</span>
        </div>

        <section v-if="step === 1">
          <v-card class="rounded-xl pa-8" color="surface" flat>
            <v-row align="center">
              <v-col cols="12" md="7">
                <div
                  class="text-body-1 font-weight-bold font-italic grey--text"
                >
                  Você está enviando
                </div>

                <v-text-field
                  v-model="form.sending"
                  v-money="_sendingMoney"
                  id="sendingValue"
                  color="primary"
                  class="text-h5"
                  hide-details="auto"
                  background-color="transparent"
                  autofocus
                  solo
                  flat
                  @keyup="fetchInPrice()"
                />
              </v-col>

              <v-col cols="12" md="5">
                <v-btn class="text-none" color="surface2" disabled block>
                  PIX

                  <v-icon right> mdi-chevron-down </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>

          <div class="d-flex align-center justify-center my-n3">
            <v-avatar
              class="swap-arrow elevation-2 rounded-lg"
              color="surface2"
              size="3rem"
            >
              <v-icon large>mdi-arrow-down</v-icon>
            </v-avatar>
          </div>

          <v-card class="rounded-xl pa-8 mb-8" color="surface" flat>
            <v-row align="center">
              <v-col cols="12" md="7">
                <div
                  class="text-body-1 font-weight-bold font-italic grey--text"
                >
                  Você está recebendo
                </div>

                <v-text-field
                  v-model="form.received"
                  v-money="_receivedMoney"
                  id="receivedValue"
                  color="primary"
                  class="text-h5"
                  hide-details="auto"
                  background-color="transparent"
                  solo
                  flat
                  @keyup="fetchOutPrice()"
                />
              </v-col>

              <v-col cols="12" md="5">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="text-none"
                      color="surface2"
                      :disabled="!currencies.length"
                      block
                    >
                      <span v-if="form.selectedCurrency">
                        {{ form.selectedCurrency.name }}
                        {{ form.selectedCurrency.symbol }}
                      </span>

                      <span v-else>--</span>

                      <v-icon right> mdi-chevron-down </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, i) in currencies"
                      :key="i"
                      @click="selectCurrency(item)"
                    >
                      <v-list-item-title>
                        {{ item.name }} {{ item.symbol }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card>

          <v-btn
            class="text-none rounded-lg"
            color="primary"
            :loading="loading"
            :disabled="loading"
            depressed
            x-large
            block
            @click="newTrade()"
          >
            <span class="text-h6 font-weight-bold surface--text">
              Realizar conversão
            </span>
          </v-btn>
        </section>

        <section v-if="step === 2">
          <v-card class="rounded-xl px-6 py-3 mb-6" color="surface" flat>
            <div class="d-flex align-center text-h6 font-italic">
              <v-sheet
                class="badge"
                color="primary"
                height="1.5rem"
                width="0.1875rem"
              />

              <span class="grey--text font-weight-light mr-2">
                Valor a ser depositado
              </span>
              <span class="white--text font-weight-bold">
                {{ form.sending }}
              </span>
            </div>
          </v-card>

          <v-row class="mb-4" justify="center">
            <v-col cols="6">
              <v-img :src="require('@/assets/openPix/op-logo.svg')" />
            </v-col>
          </v-row>

          <div class="text-body-1 text-center mb-6">
            Abra o App do seu banco, escanei a imagem ou cole o código e efetue
            o pagamento.
          </div>

          <v-row class="mb-6" justify="center">
            <v-col cols="9" sm="8" lg="7" xl="6">
              <v-img class="mb-2 bordered" :src="payment.qrCodeLink" />

              <div class="mb-4 d-flex align-center">
                <input
                  v-model="payment.link"
                  id="code"
                  class="font-weight-regular text-caption code-input pa-3"
                  readonly
                  dark
                  @focus="copyCode()"
                />
              </div>

              <v-btn
                class="text-none rounded-lg"
                color="primary"
                depressed
                x-large
                block
                @click="copyCode()"
              >
                <v-icon color="surface" left>mdi-content-copy</v-icon>

                <span class="text-h6 font-weight-bold surface--text">
                  Copiar código
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { newTrade } from "@/services/trades";
import { getCoins } from "@/services/coins";
import { getQuotes } from "@/services/quotes";
import { currencyMask, parseNumber, formatAmount } from "@/helpers";
import { VMoney } from "v-money";

export default {
  name: "Home",

  data() {
    return {
      loading: false,
      step: 1,
      money: {
        decimal: ",",
        thousands: ".",
      },
      currencies: [],
      form: {
        target: "",
        sending: "",
        received: "",
        inputedValue: 0,
        selectedCurrency: "",
      },
      currency: {},
      currencyQuote: 0,
      payment: null,
    };
  },

  directives: { money: VMoney },

  beforeMount() {
    this.getCoins();
  },

  computed: {
    _sendingMoney() {
      return {
        ...this.money,
        suffix: ` BRL`,
        precision: 2,
      };
    },

    _receivedMoney() {
      return {
        ...this.money,
        suffix: ` ${this.form.selectedCurrency?.symbol}`,
        precision: 6,
      };
    },
  },

  methods: {
    async newTrade() {
      try {
        this.loading = true;

        const payload = {
          ref: String(new Date().getTime()),
          amount: this.form.inputedValue,
          target: this.form.target,
          type: "buy",
          inputCoin: "BRL",
          outputCoin: this.form.selectedCurrency.symbol,
          address: "0x123123123123123123123123123123123123123",
          person: {
            cpf: "11111111111",
            email: "teste@email.com",
          },
        };

        const { data } = await newTrade(payload);

        this.payment = data.payment;
        this.step = 2;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getCoins() {
      try {
        const { data } = await getCoins();

        this.currencies = data;
        this.form.selectedCurrency = this.currencies[0];

        this.getQuotes();
      } catch (error) {
        console.log(error);
      }
    },

    async getQuotes() {
      try {
        this.loading = true;

        const payload = {
          symbol: this.form.selectedCurrency.symbol,
        };

        const { data } = await getQuotes(payload);

        this.currencyQuote = formatAmount(data.value, 6);

        const sendValue = parseNumber(this.form.sending);
        const value = sendValue / this.currencyQuote;

        const formatedValue = currencyMask(value, 6, 6);

        this.form.received = formatedValue;
        document.getElementById("receivedValue").value = formatedValue;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    selectCurrency(value) {
      this.form.selectedCurrency = value;
      this.getQuotes();
    },

    fetchInPrice() {
      const sendValue = parseNumber(this.form.sending);
      const value = sendValue / this.currencyQuote;

      const taxedValue =
        value +
        value * (this.form.selectedCurrency.fee.percent / 100) +
        this.form.selectedCurrency.fee.fixed;

      const formatedValue = currencyMask(taxedValue, 6, 6);

      this.form.inputedValue = sendValue;
      this.form.target = "in";

      this.$nextTick(() => {
        this.form.received = formatedValue;
        document.getElementById("receivedValue").value = formatedValue;
      });
    },

    fetchOutPrice() {
      const receiveValue = parseNumber(this.form.received);
      const value = receiveValue * this.currencyQuote;

      const taxedValue =
        value -
        value * (this.form.selectedCurrency.fee.percent / 100) -
        this.form.selectedCurrency.fee.fixed;

      const formatedValue = currencyMask(taxedValue, 2, 2);

      this.form.inputedValue = receiveValue;
      this.form.target = "out";

      this.$nextTick(() => {
        this.form.sending = formatedValue;
        document.getElementById("sendingValue").value = formatedValue;
      });
    },

    copyCode() {
      navigator.clipboard.writeText(this.payment.link);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
