import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: "#00ED5F",
        secondary: "#82B1FF",
        accent: "#82B1FF",
        error: "#FF5252",
        $info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#3f3f44",
        foreground: "#5c5c64",
        surface: "#23232d",
        surface2: "#343447",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
