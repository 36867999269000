import Axios from "axios";

const api = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { "X-Api-Key": process.env.VUE_APP_API_KEY },
  timeout: 16000,
});

// interceptor
api.interceptors.response.use(
  (res) => Promise.resolve(res),
  (err) => Promise.reject(err)
);

export default api;
